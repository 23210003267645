<!-- 申报详情 -->
<template>
  <div>
    <div>
      <v-btn class="ml-2 fl" @click="back()"> 返回上一级</v-btn>
      <v-btn class="ml-3 fl" @click="wordDownload('base')">基本导出</v-btn>
      <v-btn class="ml-3 fl" @click="dDownload">完整导出</v-btn>
      <v-btn class="ml-3 fl" @click="pdfPreview()">预览</v-btn>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fr nav">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/sbgoverning' }"
          >申报审核</el-breadcrumb-item
        >
        <el-breadcrumb-item>申报详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="clear: both;height: 16px"></div>

    <h2 class="policy-title">{{ title }}</h2>

    <div class="policy-time">
      该政策为用户第<span class="policy-number"> {{ sqnum }} </span>次申请
    </div>

    <v-row>
      <v-col cols="9">
        <v-card flat>
          <v-card-title class="authentication-information"
            ><span></span>基本信息</v-card-title
          >
          <v-card-text class="text-content">
            <v-row>
              <v-col
                cols="4"
                v-for="(item, i) in tableDate"
                :key="i"
                :style="'display:' + item.type"
              >
                <span v-if="item.value">{{ item.label }}:</span>
                <span class="content">{{ item.value }}</span>
              </v-col>
              <v-col
                cols="8"
                v-for="(item, k) in tableDateAddress"
                :key="k"
                :style="'display:' + item.type"
              >
                <span v-if="item">{{ item.label }}:</span>
                <span class="content">{{ item.value }}</span>
              </v-col>
            </v-row>

            <v-row style="width: 100%" v-if="TntroduceBool">
              <v-col cols="12">
                个人简历说明:
                <div class="policy-resume">
                  {{ resume }}
                </div>
              </v-col>
            </v-row>
            <v-row style="width: 100%" v-if="rejectContent">
              <v-col cols="12">
                驳回理由:
                <div class="policy-resume">
                  {{ rejectContent }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card flat>
          <v-card-title class="authentication-information"
            ><span></span>附件材料查看下载</v-card-title
          >

          <v-card-text>
            <v-list dense>
              <v-list-item-group v-model="item" color="primary">
                <v-list-item v-for="(item, b) in filelis" :key="b">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name"
                      class="file-text"
                      v-if="item.path && item.name"
                      @click="getFile(item.path)"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      small
                      text
                      color="primary"
                      @click="getDownloadFile(item)"
                      >下载</v-btn
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="eType !== 'talent'">
        <div v-if="roundButton === 1">
          <v-btn
            color="primary"
            @click="handle(21, '管理员通过')"
            class="mr-4 button-width"
          >
            办理
          </v-btn>
          <v-btn @click="handle(20, '管理员驳回')" class="button-width">
            驳回
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            color="success"
            depressed
            class="button-width"
            v-if="roundButton === 21"
          >
            管理员通过
          </v-btn>
          <v-btn
            color="accent"
            depressed
            class="button-width"
            v-else-if="roundButton === 20"
          >
            管理员驳回
          </v-btn>
        </div>
      </v-col>

      <v-col v-else>
        <div v-if="roundButton === 11">
          <v-btn
            color="primary"
            @click="handle(21, '管理员通过')"
            class="mr-4 button-width"
          >
            办理
          </v-btn>
          <v-btn @click="handle(20, '管理员驳回')" class="button-width">
            驳回
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            color="success"
            depressed
            class="button-width"
            v-if="roundButton === 21"
          >
            管理员通过
          </v-btn>
          <v-btn
            color="accent"
            depressed
            class="button-width"
            v-else-if="roundButton === 20"
          >
            管理员驳回
          </v-btn>
          <v-btn outlined class="button-width" v-else>
            单位未审核
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <el-dialog
      title="图片"
      :visible.sync="picDialog"
      :close-on-click-modal="true"
    >
      <img :src="picUrl" alt="" />
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input
        type="textarea"
        :rows="5"
        v-model="conterNew"
        placeholder="请输入驳回理由"
        maxlength="50"
      ></el-input>
      <div style="font-size: 12px; float: left; overflow: hidden">
        字数不超过50字
      </div>
      <div style="margin-top: 40px"></div>
      <el-button type="primary" @click="requestReject">确 定 </el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </el-dialog>

    <el-dialog title="预览图片" :visible.sync="seePhoto" width="560px">
      <v-img :src="addressId" alt="" />
    </el-dialog>
    <el-dialog
      title="PDF预览"
      v-loading="loading"
      element-loading-text="拼命加载中"
      :visible.sync="dialogPdf"
      :destroy-on-close="true"
      width="80%"
    >
      <iframe
        :src="PdfUrl"
        frameborder="0"
        id="ifra"
        v-if="PdfUrl"
        height="600"
        width="100%"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPdf = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "@/utils/config";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      dialogPdf: false,
      loading: false,
      PdfUrl: "",
      resume: "",
      item: 0,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      rejectContent: "",
      fileurl: configDate.fileurl,
      dialogVisible: false,
      seePhoto: false,
      addressId: "",
      Ids: [],
      tableData: {},
      tableDate: [],
      tableDateAddress: [],
      TntroduceBool: false,
      Title: "",
      title: "",
      sqnum: 0,
      filelis: [],
      picUrl: "",
      picWidth: 0,
      picHeight: 0,
      picDialog: false,
      multipleSelection: [],
      gridData: [],
      id: this.$route.query.id,
      putData: {
        id: "",
        status: [],
        currentStatus: "",
      },
      putStatus: {
        affairId: 0,
        memo: "管理员通过！",
        end: 1,
        ownerId: 0,
        status: 21,
      },
      eType: "",
      roundButton: 0,
      conterNew: "",
      tabletSatuses: {
        userId: "",
        affairId: "",
      },
      userId: "",
    };
  },
  created() {
    this.getDate(); //初始化 数据
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    back() {
      const policyId = this.$route.params.id.split(":")[0];
      const id = this.$route.params.id.split(":")[1];
      this.$router.push({
        path: "/sbgoverning",
        query: { policyId: policyId, policyType: this.eType },
      });
    },

    //  驳回
    requestReject() {
      this.$axios
        .post("/affair/status", {
          affairId: this.tabletSatuses.affairId,
          content: this.conterNew,
          memo: "管理员驳回",
          ownerId: this.tabletSatuses.userId,
          status: 20,
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.$root.success("办理成功");
            this.dialogVisible = false;
            this.filelis = [];
            this.getDate();
          } else {
            this.$root.warn("办理失败");
            this.dialogVisible = false;
          }
        })
        .catch((error) => {
          this.dialogVisible = false;
          console.error(error);
        });
    },

    // 下载文件
    getDownloadFile(item) {
      let path =
        item.memo === "身份证" ? item.path : configDate.fileurl + item.path;
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    handleSelectionChange(val) {},

    getDate() {
      this.filelis.length = 0;
      const self = this;
      const policyId = this.$route.params.id.split(":")[0];
      const id = this.$route.params.id.split(":")[1];

      //表单详情
      this.$axios
        .get("/policy?id=" + policyId)
        .then(function(res) {
          self.title = res.data.data.form.title;
          self.Title = res.data.data.title;
          self.eType = res.data.data.type;
          self.affairId = res.data.data.id;

          let newarr = [];
          res.data.data.form.inputs.forEach(function(item) {
            //console.log(item);
            // let ThisType =
            //   item.name.indexOf("Upload") !== -1 ? "none" : "blick";
            let ThisType = item.type === "file" ? "none" : "blick";
            newarr.push({
              type: ThisType,
              label: item.label,
              value: "",
              [item.name]: "",
            });
          });
          //事务详情
          self.$axios
            .get("/affair?id=" + id)
            .then(function(res) {
              self.roundButton = res.data.data.currentStatus;
              self.putData.id = res.data.data.id;
              self.putData.status = res.data.data.status;
              self.tabletSatuses.userId = res.data.data.ownerId;
              self.tabletSatuses.affairId = res.data.data.id;
              self.putStatus.affairId = res.data.data.id;
              self.putStatus.ownerId = res.data.data.ownerId;
              self.getPersonnelResume(self.putStatus.ownerId);
              let separate = [];
              console.log(res.data.data.values);
              res.data.data.values.forEach(function(item) {
                if (item.name.indexOf("Upload") !== -1) {
                  self.getFlelis(item.value);
                } else if (
                  item.name == "dishonesty" ||
                  item.name == "socialSecurity" ||
                  item.name == "serviceDeclaration" ||
                  item.name == "recordRegistration" ||
                  item.name == "declarationAttachment"
                ) {
                  self.getFlelis(item.value);
                } else {
                  separate.push(item);
                }
              });

              let url =
                "/affair/statuses?affairId=" +
                self.tabletSatuses.affairId +
                "&userId=" +
                self.tabletSatuses.userId;
              self.$axios.get(url).then((response) => {
                self.affairStatusId =
                  response.data.data[response.data.data.length - 1].id;
                self.rejectContent =
                  response.data.data[response.data.data.length - 1].content;
              });

              separate.forEach(function(item, i) {
                newarr.forEach(function(list, index) {
                  if (item.label === list.label) {
                    if (item.name === "talentCheckbox2") {
                      newarr[index].value = JSON.parse(item.value);
                    } else {
                      newarr[index].value = item.value;
                    }
                  }
                });
              });
            })
            .catch(function(error) {
              console.error(error);
            });

          self.tableData = newarr;
          self.tableDate = [];
          self.tableDateAddress = [];

          self.tableData.forEach((item) => {
            if (item.label === "个人简历") {
              self.TntroduceBool = true;
            }
            if (item.label !== "户籍所在地") {
              self.tableDate.push(item);
            } else {
              self.tableDateAddress.push(item);
            }
          });
          //let ThisType = item.type === "file" ? "none" : "blick";
        })
        .catch(function(error) {
          console.error(error);
        });
      self.getsqnum(policyId, id);
    },

    getPersonnelResume(id) {
      this.$axios.get("/talent/detail?id=" + id).then((response) => {
        if (response.data.status === 200) {
          this.resume = response.data.data.resume;
        }
      });
    },

    getsqnum(policyId, id) {
      const self = this;
      self.$axios.get("/affair?id=" + id).then((res) => {
        self.userId = res.data.data.ownerId;

        self.$axios
          .get("/affair/apply-times/success", {
            params: {
              policyId: policyId,
              userId: self.userId,
            },
          })
          .then((res) => {
            self.sqnum = res.data.data;
          });
      });
    },
    //下载
    downloaded(path) {
      window.location.href = this.fileurl + path;
    },

    getFile(path) {
      this.seePhoto = true;
      this.addressId = configDate.fileurl + path;
    },

    getFlelis(ID) {
      console.log(ID);
      var self = this;
      this.$axios.get("/file/record?id=" + ID).then(function(res) {
        self.filelis.push({
          name: res.data.data.memo,
          path: res.data.data.path,
        });
      });
    },

    //办理
    handle(status, memo) {
      if (status === 20) {
        this.dialogVisible = true;
        return false;
      }
      const self = this;
      this.putData.currentStatus = status;
      this.$confirm("确定此操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/affair/status", this.putStatus)
            .then(function(res) {
              if (res.data.status === 200) {
                self.$notify({
                  title: "审核成功",
                  message: res.data.data,
                  type: "success",
                });
                self.$router.back();
              }
            });
        })
        .catch(() => {
          self.$notify({
            title: "成功取消",
            message: "取消更新！",
            type: "success",
          });
        });
    },
    handleEdit(index, row) {},
    //预览
    pdfPreview() {
      let slef = this;
      let affairId, talentId, enterpriseId;
      affairId = sessionStorage.getItem("affairId");
      talentId = sessionStorage.getItem("talentId");
      enterpriseId = sessionStorage.getItem("enterpriseId");

      this.loading = true;
      this.dialogPdf = true;

      this.PdfUrl = this.dialogPdf
        ? configDate.url +
          "/export/preview?affairId=" +
          affairId +
          "&talentId=" +
          talentId +
          "&enterpriseId=" +
          enterpriseId
        : "";
      setTimeout(function() {
        slef.loading = false;
        console.log("0");
      }, 3000);
    },

    //完整导出
    dDownload() {
      let affairId, talentId, enterpriseId, DePdfUrl;
      affairId = sessionStorage.getItem("affairId");
      talentId = sessionStorage.getItem("talentId");
      enterpriseId = sessionStorage.getItem("enterpriseId");
      DePdfUrl =
        configDate.url +
        "/export?affairId=" +
        affairId +
        "&talentId=" +
        talentId +
        "&enterpriseId=" +
        enterpriseId;
      window.open(DePdfUrl);
    },

    //Wc导出
    wordDownload(type) {
      let WordName = this.title;
      this.tableData.forEach((item) => {
        if (item.label === "姓名" && item.value) {
          WordName = item.value + "_" + WordName;
        }
      });

      const id = this.$route.params.id.split(":")[1];
      this.$axios
        .get("/affair/word/" + type + "?id=" + id, {
          responseType: "blob", // 设置响应数据类型
        })
        .then(function(res) {
          console.log(res);
          if (res.status === 200) {
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", WordName + ".pdf");
            document.body.appendChild(link);
            link.click();
          }
        });
    },
  },
  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        return value1 === value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.policy-title {
  color: #128fec;
  text-align: left;
  margin: 0 0 12px 8px;
}

.policy-time {
  text-align: left;
  margin-left: 8px;
  margin-bottom: 16px;
}

.policy-resume {
  display: block;
  width: 100%;
  min-height: 80px;
  padding: 8px 16px;
  border: 1px #8a8f97 dashed;
  background-color: #f5f7fa;
  border-radius: 8px;
}

.file-text {
  text-align: left;
}

.policy-number {
  text-align: left;
  color: #128fec;
}

.authentication-information {
  color: #1090e7;
  font-weight: bold;
  position: relative;

  span {
    position: absolute;
    left: 0;
    display: inline-block;
    height: 20px;
    width: 3px;
    background-color: #1090e7;
  }
}

.text-content {
  text-align: left;
  margin: 0 8px;
  color: #000000;
  font-size: 16px;

  span {
    color: #8a8f97;
  }

  .content {
    margin-left: 8px;
    color: #2a3746;
  }
}

.button-width {
  width: 96px;
}

.headtit {
  background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;
}

.buttbut {
  overflow: hidden;
  width: 500px;
  margin: 0 auto;
}

.basicCoxs {
  overflow: hidden;
  //
  text-align: left;

  .cailxx {
    span {
      margin: 1px;
    }
  }

  .fujianx {
    span {
      position: relative;
      width: 96%;
      height: 38px;
      line-height: 38px;
      background: #fafafa;
      border: 1px #dcdcdc solid;
      margin: 8px;
      text-align: left;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
      overflow: hidden;
    }
  }

  .row {
    span {
      width: 40%;
      height: 35px;
      background: #fff;
      padding: 0 16px;
      border: 1px #1090e7 solid;
      text-align: center;
      cursor: pointer;
      min-width: 280px;
    }
  }

  span {
    background: #f4f9fe;
    font-size: 14px;
    display: block;
    height: 35px;
    text-align: left;
    padding: 0 16px;
    line-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    margin: 4px 8px;
    min-width: 280px;
    border: 1px solid #e8ecef;

    .seeBg {
      position: absolute;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      left: 0;
      top: 0;
      cursor: default;
      text-align: right;
      color: #fff;
      padding-right: 8px;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;

      .see {
        cursor: pointer;
      }
    }

    &:hover {
      .seeBg {
        opacity: 1;
      }
    }
  }
}

.size-div {
  font-size: 14px;
  margin: 16px 0 0 12px;
}
</style>
